<section class="section-alert">
  <div class="alert alert-warning" role="alert">
    <div class="alert-icon icomoon-info"></div>
    <div class="alert-heading">
      We support ERC20 tokens. Rebase and deflationary tokens are not supported!
    </div>
  </div>
</section>

<section class="__create-pool-section">
  <form #createStakingPoolForm="ngForm" (ngSubmit)="confirmForm()" class="__create-pool-form">
    <div class="p-40">

      <div class="row mb-40">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <div class="card p-4">

            <mat-form-field appearance="fill" hideRequiredMarker class="mb-2">
              <mat-label>Token Contract</mat-label>
              <input matInput
                     type="text"
                     [(ngModel)]="stagingToken"
                     name="stagingToken"
                     [disabled]="step>1 || waiting"
                     required
              >
            </mat-form-field>

            <mat-form-field appearance="fill" hideRequiredMarker class="mb-3">
              <mat-label>Reward Token Supply</mat-label>
              <input matInput
                     type="text"
                     [(ngModel)]="poolTokenAmount"
                     name="poolTokenAmount"
                     [disabled]="step>1 || waiting"
                     required
              >
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card p-4">
            <div class="mb-2">
              <div class="small-text mb-3">
                {{startTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}
              </div>

              <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-label>Start Timestamp</mat-label>
                <input matInput
                       type="number"
                       [(ngModel)]="startTimestamp"
                       name="startTimestamp"
                       [disabled]="step>1 || waiting"
                       required
                >
              </mat-form-field>
            </div>

            <div class="">
              <div class="small-text mb-3">
                {{finishTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}
              </div>

              <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-label>End Timestamp</mat-label>
                <input matInput
                       type="number"
                       [(ngModel)]="finishTimestamp"
                       name="finishTimestamp"
                       [disabled]="step>1 || waiting"
                       required
                >
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- <button type="submit" class="btn btn-primary w-100" (click)="clickStep1()" [ngClass]="{'wait': waiting}" [disabled]="waiting" *ngIf="step==1">Check Fee</button>
    <button type="submit" class="btn btn-primary w-100" (click)="approveRewardTokenClick()" [ngClass]="{'wait': waiting}" [disabled]="waiting" *ngIf="step==2">Approve Reward Token</button>
    <button type="submit" class="btn btn-primary w-100" (click)="createStakingPoolTest()" [ngClass]="{'wait': waiting}" [disabled]="waiting" *ngIf="step==3">Create Pool</button> -->

    <div class="form-save bg-white mt-auto">
      <button type="submit"
              *ngIf="step==1"
              mat-raised-button
              color="primary"
              class="w-100 btn-icon btn-icon-left"
              [ngClass]="{'btn-loading': waiting}"
              [disabled]="!createStakingPoolForm.form.valid || waiting">
        <i class="icon icomoon-plus"></i>
        Confirm
      </button>

      <div *ngIf="step==2">
        <div *ngIf="!feeTokenApproved" class="btn-group-justified">
          <button type="button"
                  mat-stroked-button
                  (click)="toStep1()"
                  [disabled]="waiting">
            Back
          </button>

          <button type="button"
                  mat-raised-button
                  color="primary"
                  class="btn-icon btn-icon-left"
                  [ngClass]="{'btn-loading': waiting}"
                  [disabled]="waiting"
                  (click)="approveFeeTokenClick()">
            <i class="icon icomoon-checkmark"></i>
            Approve fee token
          </button>
        </div>
      </div>

      <div *ngIf="step==3">
        <div *ngIf="!rewardTokenApproved" class="btn-group-justified">
          <button type="button"
                  mat-stroked-button
                  (click)="toStep1()"
                  [disabled]="waiting">
            Back
          </button>

          <button type="button"
                  mat-raised-button
                  color="primary"
                  class="btn-icon btn-icon-left"
                  [ngClass]="{'btn-loading': waiting}"
                  [disabled]="waiting"
                  (click)="approveRewardTokenClick()">
            <i class="icon icomoon-checkmark"></i>
            Approve reward token
          </button>
        </div>
      </div>

      <div *ngIf="step==4">
        <div *ngIf="rewardTokenApproved" class="btn-group-justified">
          <button type="button"
                  mat-stroked-button
                  (click)="toStep1()"
                  [disabled]="waiting">
            Back
          </button>
          <button type="button"
                  mat-raised-button
                  color="primary"
                  class="btn-icon btn-icon-left"
                  [ngClass]="{'btn-loading': waiting}"
                  [disabled]="waiting"
                  (click)="createStakingPoolClick()">
            <i class="icon icomoon-plus"></i>
            Create Staking Pool
          </button>
        </div>
      </div>
    </div>
  </form>
</section>

