<main class="dashboard-main">
  <section class="create-pool-section" [ngClass]="{'loading-page': waitingPenalty}">
    <mat-tab-group mat-align-tabs="start" class="fullpage-tabs" disableRipple>
      <mat-tab label="General">
        <div class="p-40">
          <div class="row mb-20">
            <div class="col-12 mb-20">
              <div class="card p-4">
                <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                  <mat-label>Token Contract</mat-label>
                  <input matInput type="text" [(ngModel)]="stakingToken" name="stakingToken" disabled>
                </mat-form-field>

                <mat-form-field appearance="fill" hideRequiredMarker>
                  <mat-label>Reward Token Supply</mat-label>
                  <input matInput type="text" [(ngModel)]="poolTokenAmount" name="poolTokenAmount" disabled>
                </mat-form-field>

                <!-- <div class="col-lg-6">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="pool-token-per-block" placeholder="Tokens per Block" />
                      <label for="pool-token-per-block">Tokens per Block</label>
                    </div>
                  </div> -->

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 mb-3 mb-lg-0">
              <div class="card p-4">
                <div class="subtitle-1 text-break mb-3">Start Date (UTC)</div>
                <div class="small-text mb-3">
                  {{startTime * 1000 | date:'dd MMM, yyyy H:mm a'}}
                </div>

                <mat-form-field appearance="fill">
                  <mat-label>Start Timestamp</mat-label>
                  <input matInput type="number" [(ngModel)]="startTime" name="startTime" disabled>
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card p-4">
                <div class="subtitle-1 text-break mb-3">End Date (UTC)</div>
                <div class="small-text mb-3">
                  {{finishTime * 1000 | date:'dd MMM, yyyy H:mm a'}}
                </div>

                <mat-form-field appearance="fill">
                  <mat-label>End Timestamp</mat-label>
                  <input matInput type="number" [(ngModel)]="finishTime" name="finishTime" disabled>
                </mat-form-field>
                
                <div class="row mt-4">
                  <div class="col-6">
                    <mat-form-field appearance="fill" *ngIf="extending">
                      <mat-label>Token Amount</mat-label>
                      <input matInput type="number" [(ngModel)]="extendTokenAmount" [disabled]="waiting"
                        name="extendTokenAmount">
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="fill" *ngIf="extending">
                      <mat-label>Duration (empty to auto)</mat-label>
                      <input matInput type="number" [(ngModel)]="extendDuration" [disabled]="waiting"
                        name="extendDuration">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row mt-4" *ngIf="!reducing">
                  <div class="col">
                    <button type="submit" class="w-100" mat-raised-button color="primary" [disabled]="waiting"
                      (click)="extending = !extending">
                      {{extending ? 'Cancel' : 'Extend'}}
                    </button>
                  </div>
                  <div class="col" *ngIf="extending">
                    <button type="submit" class="w-100" mat-raised-button color="primary" [disabled]="waiting"
                      [ngClass]="{'btn-loading': waiting}" (click)="extendPoolDuration()">
                      Apply extending
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <mat-form-field appearance="fill" *ngIf="reducing">
                      <mat-label>New finish time</mat-label>
                      <input matInput type="number" [(ngModel)]="reduceTime" [disabled]="waiting"
                        name="reduceTime">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row mt-4" *ngIf="!extending">
                  <div class="col">
                    <button type="submit" class="w-100" mat-raised-button color="primary" [disabled]="waiting"
                      (click)="reducing = !reducing">
                      {{reducing ? 'Cancel' : 'Reduce'}}
                    </button>
                  </div>
                  <div class="col" *ngIf="reducing">
                    <button type="submit" class="w-100" mat-raised-button color="primary" [disabled]="waiting"
                      [ngClass]="{'btn-loading': waiting}" (click)="reducePoolDuration()">
                      Apply reducing
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tiers-table table w-100 mt-4">
            <div class="table-row table-head">
              <div class="item">#</div>
              <div class="data item">Period</div>
              <div class="data item">Penalty</div>
              <div>&nbsp;</div>
            </div>
            <div *ngFor="let item of allPenalties; index as i;" class="table-row">
              <div data-title="Index" class="item">
                {{i}}
              </div>
              <div data-title="Period" class="data item" [ngClass]="{'edit-opened': i==updatePenaltyIndex}">
                <div [ngClass]="{'mb-3': i==updatePenaltyIndex}">
                  {{item.duration / 86400}} Days
                </div>

                <mat-form-field appearance="fill" *ngIf="i==updatePenaltyIndex">
                  <mat-label>Period</mat-label>
                  <input matInput type="number" [(ngModel)]="updatePenaltyPeriod" name="updatePenaltyPeriod" required>
                </mat-form-field>
              </div>
              <div data-title="Penalty" class="data item" [ngClass]="{'edit-opened': i==updatePenaltyIndex}">
                <div [ngClass]="{'mb-3': i==updatePenaltyIndex}">
                  {{item.penaltyBP / 100}} %
                </div>

                <mat-form-field appearance="fill" *ngIf="i==updatePenaltyIndex">
                  <mat-label>Penalty</mat-label>
                  <input matInput type="number" [(ngModel)]="updatePenaltyPercent" name="updatePenaltyPercent" required>
                </mat-form-field>
              </div>
              <div>
                <button *ngIf="i!=updatePenaltyIndex" mat-icon-button [disabled]="waitingPenalty" (click)="select(i)"
                  matTooltip="Edit Penalty" matTooltipPosition="above">
                  <span class="icomoon-edit"></span>
                </button>
                <button *ngIf="i==updatePenaltyIndex" mat-icon-button [disabled]="waitingPenalty"
                  (click)="updatePenaltyClick()" matTooltip="Update Penalty" matTooltipPosition="above">
                  <span class="icomoon-checkmark"></span>
                </button>
              </div>
            </div>
          </div>

        </div>
      </mat-tab>

      <mat-tab label="Appearance">
        <div class="__min-height">
          <div class="p-40">
            <div class="row mb-40">
              <div class="col-lg-6">
                <div class="card p-4 mb-4 mb-lg-0">
                  <mat-form-field appearance="fill" hideRequiredMarker class="mb-3">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" [(ngModel)]="poolName" name="poolName" required>
                  </mat-form-field>

                  <mat-form-field appearance="fill" hideRequiredMarker class="mb-3">
                    <mat-label>Description</mat-label>
                    <textarea matInput rows="6" [(ngModel)]="poolDescription" name="poolDescription" required>
                    </textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="__is-visible card p-4 mb-4 d-flex align-items-center">
                  <div class="subtitle-1 me-auto">Add to tier count</div>
                  <mat-slide-toggle name="addToTierCount" [(ngModel)]="addToTierCount">
                  </mat-slide-toggle>
                </div>

                <div class="__is-visible card p-4 d-flex align-items-center">
                  <div class="subtitle-1 me-auto">Visible for Users</div>
                  <mat-slide-toggle name="visibleForUsers" [(ngModel)]="visibleForUsers">
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <div class="form-save mt-auto bg-white">
            <button type="submit" class="w-100" mat-raised-button color="primary" (click)="updateStakingPoolDb()">
              Save Changes
            </button>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Whitelist">
        <div class="__min-height">
          <div class="p-40">
            <div class="card p-4 mb-40">
              <div class="d-flex align-items-center">
                <div class="subtitle-1 me-auto">Whitelisting</div>
                <mat-slide-toggle name="hasWhiteListing" [(ngModel)]="hasWhiteListing">
                </mat-slide-toggle>
              </div>
            </div>

            <div class="card p-4 mb-40">
              <div class="subtitle-1 mb-4">Whitelist</div>

              <mat-form-field appearance="fill">
                <mat-label>Whitelisted Addresses</mat-label>
                <textarea matInput [(ngModel)]="stringWhitelist" name="stringWhitelist" rows="15"
                  [disabled]="!hasWhiteListing">
                ></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="form-save mt-auto bg-white">
            <button type="submit" class="w-100" mat-raised-button color="primary" (click)="updateWhitelistClick()">
              Save Whitelist
            </button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </section>
</main>